import React from "react";
import { Accordion } from "react-bootstrap";
import Header from "../components/Header";

export default function Home() {
  return (
    <>
      <Header />
      <hr />
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Szülihavi ünneplés</Accordion.Header>
          <Accordion.Body>
            Közösen elmegyünk a kedvenc pihenős wellness hoteledbe. Találd ki, hogy mi az!{" "}
            <a href="/unneples">Tovább...</a>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Szülihavi kényeztetés</Accordion.Header>
          <Accordion.Body>
            Nagyon jó a hely, olyan, ahol fontos dolgok történtek veled. A válasz lehet, hogy csalós.{" "}
            <a href="/pihenes">Tovább...</a>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Szülihavi kirándulás</Accordion.Header>
          <Accordion.Body>
            Ez lesz a legnehezebb. Egyszer említetted, hogy megnéznéd ilyenkor is. Hint: pálma, nem magyarország.{" "}
            <a href="/kirandulas">Tovább...</a>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
