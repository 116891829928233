import { useState } from "react";
import Back from "../components/Back";
import Password from "../components/Password";

const jelszo = "opatija";

export default function Kirandulas() {
  const [input, setInput] = useState("");

  if (input != jelszo) {
    return (
      <Password input={input} setInput={setInput}>
        Ez lesz a legnehezebb. Egyszer említetted, hogy megnéznéd ilyenkor is, és tök extra lenne itt tölteni ebből az
        időszakból. Hint: pálma, hideg, kisváros.
      </Password>
    );
  }

  return (
    <>
      <Back />
      <h1 className="mb-4">Gratulálok! Helyes a válasz! </h1>
      <img src="/advent.jpeg" className="img-fluid mb-4" />

      <p className="fw-bold">Elmegyünk adventezni. Pontosabban egy adventi körútra.</p>
      <p>
        Először is elmegyünk Zágrábba, megnézzük az ottani adventet, amit tavaly a legjobbnak válaszottak Európában.
      </p>
      <p>
        Utána megnézzük az opatijai adventet a tengerparton, pálmák, között egy olyan apartmanból, amiből tengeri
        kilátás van.
      </p>
      <p>Szóval készülj fel, mert megyünk egy nagyobbat, mint a legutóbbi adventi tourunk.</p>
      <p>Időpont: December 1-2-3</p>
    </>
  );
}
