import React from "react";

export default function Back() {
  return (
    <div clasName="border-bottom">
      <a href="/" className="btn btn-secondary btn-sm">
        Vissza
      </a>
      <hr />
    </div>
  );
}
