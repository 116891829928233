import { useState } from "react";
import Back from "../components/Back";
import Password from "../components/Password";

const jelszo = "szeged";

export default function Pihenes() {
  const [input, setInput] = useState("");

  if (input != jelszo) {
    return (
      <Password input={input} setInput={setInput}>
        Nagyon jó a hely, olyan, ahol fontos dolgok történtek veled. A megfejtés, lehet, hogy könnyű, de jelszó nem
        egyértelmű!
      </Password>
    );
  }

  return (
    <>
      <Back />
      <h1 className="mb-4">Gratulálok! Helyes a válasz! </h1>
      <img src="colosseum.jpg" className="img-fluid mb-4" />

      <p className="fw-bold">Elmegyünk a Colosseum**** hotelbe 4 napra pihenni.</p>
      <p>A hotel lényege, hogy Szeged mellett van, így nagyon közel van Makóhoz is. Ahol a legszebb választ adtad!</p>
      <p>
        A hotelhez tartozik egy fürdőkomplekszum, ami egy-két éve az ország legjobb fürdője volt. De ez nem minden. A
        hotelben található egy külön wellness részleg rengeteg szaunával és medencével, kiváló konyhával, ahol séf főz
        rád napi kétszer. Köztes időben pedig fürdeni és kirándulni fogunk.
      </p>
      <p>Bizony, kirándulni, mert itt van Szeged, Makó, Szabadka és a mostani mórahalmi környék.</p>
      <p>Időpont: November 25-26-27-28.</p>
    </>
  );
}
