import { useState } from "react";
import Back from "./Back";
import { Form } from "react-bootstrap";

export default function Password({ input, setInput, children }) {
  return (
    <>
      <Back />
      <p>{children}</p>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Rájöttél a jelszóra? Írd be! Ha helyes a válasz megnyílik.</Form.Label>
        <Form.Control type="text" value={input} onChange={(e) => setInput(e.target.value.toLowerCase())} />
      </Form.Group>
    </>
  );
}
