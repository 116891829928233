import { useState, useEffect } from "react";
import Back from "../components/Back";
import Password from "../components/Password";

const jelszo = "portobello";

export default function Unneples() {
  const [input, setInput] = useState("");

  if (input != jelszo) {
    return (
      <Password input={input} setInput={setInput}>
        Közösen elmegyünk a kedvenc pihenős wellness hoteledbe. Találd ki, hogy mi az!
      </Password>
    );
  }

  return (
    <>
      <Back />

      <h1 className="mb-4">Gratulálok! Helyes a válasz! </h1>
      <img src="/portobello.jpg" className="mb-4 img-fluid" />

      <p>Nem itthon fogod ünnepelni a szülinapodat, hanem elmegyünk négyen a Portobello hotelbe.</p>
      <p>Csak holnap fogunk hazajönni, így lesz egy jó vacsi, és reggeli is, sok-sok fürdéssel egybekötve.</p>
    </>
  );
}
