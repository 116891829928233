import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Home from '../src/pages/home'
import Kirandulas from "./pages/kirandulas";
import Pihenes from "./pages/pihenes";
import Unneples from "./pages/unneples";

function App() {
  return (
    <>
      <Container className="py-3 d-block d-lg-none bg-light" style={{ minHeight: '100vh' }}>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="kirandulas" element={<Kirandulas />} />
            <Route path="unneples" element={<Unneples />} />
            <Route path="pihenes" element={<Pihenes />} />
          </Routes>
        </BrowserRouter>

      </Container>
      <p className="py-3 d-none d-lg-block">This is a mobile App, not working on desktop machines.</p>
    </>
  );
}

export default App;
