import React from "react";

export default function Header() {
  return (
    <div>
      <h1 className="h3">Kedves Feleségem!</h1>
      <p>
        A mai napon te is végre 30 lettél és összefogott a családod. A szülinapod alkalmából szülihavi meglepétésben
        részesülsz, ez azt jelenti, hogy egész hónapban mindig történni fog veled valami.
      </p>
      <p>
        Minden programot egy-egy jelszó nyit, amire utaló mondatot találsz a program rövid leírásában. Ha kitalálod a
        helyes megfejtést, láthatod a részleteket. Ha nem akkor minden meglepetés lesz számodra!
      </p>
      <p className="small">A megfejtés mindig egy szó, nem számít a kis és nagy betű.</p>
    </div>
  );
}
